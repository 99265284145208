<template>
    <section class="payment-diary">
        <div class="top-bar bg-white" style="justify-content: left">
          <el-input size="small" style="width: 260px;margin-right: 10px;" v-model="formSearch.keyword"
                    placeholder="请输入房源/租户姓名"/>
          <div style="height: 30px;line-height: 30px;margin-right: 20px"> 账单开始时间</div>
            <el-date-picker v-model="paidDate" type="daterange" value-format="yyyy-MM-dd" format="yyyy-MM-dd"
                            range-separator="~" start-placeholder="起始时间" end-placeholder="截止时间"
                            size="small" style="width: 250px;margin-right: 10px"/>
<!--            <el-date-picker v-model="payBillDate" type="daterange" value-format="yyyy-MM-dd" format="yyyy-MM-dd"-->
<!--                            range-separator="~" start-placeholder="截至时间" end-placeholder="结束日期"-->
<!--                            size="small" style="width: 250px;margin-right: 10px"/>-->

            <el-select v-model="formSearch.billType" placeholder="请选择账单类型"
                       style="width: 200px;margin-right: 10px" size="small">
                <el-option v-for="item in billTypes" :key="item" :label="item.name" :value="item.code"/>
            </el-select>
            <el-select v-model="formSearch.billStatus" placeholder="请选择账单状态"
                       style="width: 200px;margin-right: 10px" size="small">
                <el-option v-for="item in natureCodeList" :key="item" :label="item.name" :value="item.code"/>
            </el-select>
            <el-button type="primary" @click="handleSearch" size="small">查询</el-button>
            <el-button @click="handleReset" size="small">重置</el-button>
          <el-button type="primary" size="small" @click="handleExportDownload">导入模板<i class="el-icon-bottom el-icon--right"></i></el-button>
          <el-button type="primary" size="small" @click="handleExport">导入</el-button>
          <el-button type="primary" size="small" @click="handleExportDerive">导出</el-button>
        </div>
        <div class="bg-white">
            <r-e-table class="bg-white" ref="tableRef" :dataRequest="getProceedsListApi" :columns="tableColumn"
                       :query="formSearch" :height="790" @select-all="selectallClick" @select="selectallClick" >
<!--         show-summary     :summary-method="getSummaries"-->

                <template slot="empty">
                    <el-empty/>
                </template>
              <el-table-column slot="toolbar" label="操作" width="100">
                <template slot-scope="{ row }">
                  <div class="table-tools">
                    <el-button size="mini" type="text"  @click="bntBillDetails(row)">详情</el-button>
                    <!--<el-button size="mini">已开</el-button>-->
                  </div>
                </template>
              </el-table-column>
            </r-e-table>
        </div>
      <approvalXiangqing   ref="approvalXiangqingREF"></approvalXiangqing>
      <layer-assets-import ref="layerAssetsImport" @click-cancel="handleSearch" />
    </section>
</template>

<script>
    import {numberFormat} from "@custom";
    import {tableColumn} from "./data";
    import {getProceedsPayTotalListApi, proceedsExport, } from "@/api/payment-diary";
    import {downloadByData} from "@/utils/hooks/download";
    import {timeFormat} from "@custom/index";
    import {getPaybillListApi, handleExportDownload} from "@/api/bill";
    import * as zlib from "zlib";
    import {MessageWarning} from "@custom/message";
    import el from "element-ui/src/locale/lang/el";

    export default {
        name: "billApproval",
        data() {
            return {
                tableColumn: tableColumn(this),
                formSearch: {
                    dt1: null,
                    dt2: null,
                    keyword: null,

                    payBillTypes: null,
                    payTypes: null,

                   billType: null,
                  billStatus: null,
                },
                natureCodeList: [{name:"全部",code:'-1'},{name:"待处理",code:'600601'},{name:"已缴费",code:'600602'},{name:"已逾期",code:'600603'},{name:"已作废",code:'600604'},{name:"未完成",code:'600605'} ],
                billTypes: [{name:"全部",code:'-1'},{name:"电费",code:'500501'},{name:"水费",code:'500502'}],
                paidDate: [],
                payBillDate: [],
                payBillTypes: [],
                payTypes: [],
                payBillUuids: [],
                loadingOptions: {
                    lock: true,
                    text: "Loading...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)"
                },
                payAmount: 0,
                payBillAmount: 0,
                receivableAmount: 0,
                total: 0,
            };
        },
      components: {
        approvalXiangqing: () => import("@/views/bill-management/bill-approval/components/approval-xiangqing.vue"),
        layerAssetsImport: () => import("./components/layer-assets-import"),
      },
        methods: {
          selectallClick(value,selectitem){
            this.payBillUuids = value.map(item => item.uuid)
          },
            getProceedsListApi(params) {
                return getProceedsPayTotalListApi(params);
            },
            handleSearch() {
                this.$refs["tableRef"].getTableData();

            },
            handleReset() {
                this.formSearch = {
                  dt1: null,
                  dt2: null,
                    keyword: null,
                    payBillTypes: null,
                    payTypes: null,
                };
                this.paidDate = [];
                this.payBillDate = [];
                this.payBillTypes = [];
                this.payTypes = [];
               setTimeout(()=> { this.handleSearch();})
            },
            handleExport() {
              this.$refs["layerAssetsImport"].openDialog();
                // const formSearch = this.formSearch;
                // const loadingOptions = this.loadingOptions;
                // const loading = this.$loading({...loadingOptions});
                // proceedsExport({...formSearch}).then(res => {
                //     downloadByData({data: res, filename: `收付日记${timeFormat(new Date(), 'yyyy-MM-dd-HH-mm-ss')}.xls`});
                //     loading.close();
                // }).catch(err => loading.close());
            },
            getSummaries(param) {
                const {columns, data} = param;
                const sums = [];
                const payAmount = this.payAmount;
                const payBillAmount = this.payBillAmount;
                const receivableAmount = this.receivableAmount;
                const total = this.total;
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = '合计';
                        return;
                    }
                    if (index === 5 || index === 8 || index === 9 || index === 10) {
                        sums[5] = numberFormat(payBillAmount / 100);
                        sums[8] = numberFormat(receivableAmount / 100);
                        sums[9] = numberFormat(payAmount / 100);
                        sums[10] = numberFormat(total / 100);
                    } else sums[index] = '';
                });
                return sums;
            },

          bntBillDetails(data){
            // this.$refs.approvalXiangqingREF.detailsDialogVisible = true;
            this.$refs.approvalXiangqingREF.goBack(data)
          },
          // 下载模板
          handleExportDownload(){
            const loading = this.$loading({lock: true, text: "正在下载模板请您稍等...", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)"});
            handleExportDownload().then(res => {
              // console.log(res,res.info.codeName)
              // let url = res.info.dataValue
              // const a = document.createElement('a'); // 创建一个a标签
              // a.href = url; // 设置文件的URL
              // a.download = '账单' ; // 设置下载的文件名，默认为URL的最后部分
              // document.body.appendChild(a); // 将a标签添加到页面中
              // a.click(); // 模拟点击事件，触发下载
              // document.body.removeChild(a); // 下载完成后移除a标签
              window.location.assign(res.info.dataValue)
            }).finally(() => loading.close());
          },
          // 导出
          handleExportDerive(){
            console.log(this.payBillUuids)
            if(this.payBillUuids.length === 0){
              MessageWarning('请选择需要导出的账单')
             return
            }
            const loading = this.$loading({lock: true, text: "正在导出请您稍等...", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)"});
            getPaybillListApi({uuids: this.payBillUuids}).then(res => {
              console.log(res)
              downloadByData({data: res, filename: `账单${timeFormat(new Date(), 'yyyy-MM-dd-HH-mm-ss')}.xls`});
            }).finally(() => loading.close());
          }
        },
        watch: {
            paidDate(value) {
                if (value && value.length === 2) {
                    let [paidStartDate, paidEndDate] = value;
                    this.formSearch.dt1 = paidStartDate;
                    this.formSearch.dt2 = paidEndDate;
                } else {
                    this.formSearch.dt1 = null;
                    this.formSearch.dt2 = null;
                }
            },
            payBillDate(value) {
                if (value && value.length === 2) {
                    let [payBillStartDate, payBillEndDate] = value;
                    this.formSearch.dt1 = payBillStartDate;
                    this.formSearch.dt2 = payBillEndDate;
                } else {
                    this.formSearch.dt1 = null;
                    this.formSearch.dt2 = null;
                }
            },
            // payBillTypes(value) {
            //     if (value && value.length !== 0) this.formSearch.payBillTypes = value.join(";");
            //     else this.formSearch.payBillTypes = null;
            // },
            // payTypes(value) {
            //     if (value && value.length !== 0) this.formSearch.payTypes = value.join(";");
            //     else this.formSearch.payTypes = null;
            // },
        },
        async mounted() {
        },
    }
</script>

<style lang="scss" scoped>
    .payment-diary {
        padding: VH(15px) VW(15px);

        .top-bar {
            display: flex;
            margin: VH(10px) 0 VH(25px);
            padding: VH(15px) VW(15px);
            justify-content: center;

            .el-radio-button.is-active {
                box-shadow: 0 0 10px #DDD inset;
            }
        }
    }
</style>
