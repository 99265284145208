import {timeFormat, numberFormat} from "@custom/index";

export function tableColumn(_this) {
    return [
        {
            type: "selection",
            width: 55,
            fixed: 'left'
        },
        {
            type: "index",
            label: "序号",
            width: 60,
        },
        {
            prop: "communityName",
            label: "小区名称",
            width: 150,
        },
        {
            prop: "apartmentName",
            label: "房源",

            render: (h, {row}) => {
                const {apartmentName} = row;
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {
                        click() {
                            _this.$router.push({
                                name: "house-resource",
                                query: {data: {apartmerntName: apartmentName}}
                            });
                        }
                    }
                }, apartmentName);
            }
        },
        {
            prop: "leasorName",
            label: "租户名称",
            width: 80,
            render: (h, {row}) => {
                const {leasorName} = row;
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {
                        click() {
                            _this.$router.push({name: "tenant-management", params: {data: {leasorName}}});
                        }
                    }
                }, leasorName);
            }
        },
        {
            prop: "contractPeriod",
            label: "合同周期",
            width: 200,

        },
        {
            prop: "billTypeName",
            label: "账单类型",
            width: 90,

        },
        {
            prop: "startDate",
            label: "账单开始时间",
            width: 100,
            render: (h, {row}) => {
                const {startDate = null, } = row;
                if (startDate) return h("span", timeFormat(new Date(startDate)));
            }
        },
        {
            prop: "endDate",
            label: "账单结束时间",
            width: 100,
            render: (h, {row}) => {
                const { endDate = null} = row;
                if ( endDate) return h("span", timeFormat(new Date(endDate)));
            }
        },
        {
            prop: "amount",
            label: "账单金额 (元)",
            width: 120,
            render: (h, {row}) => {
                const {amount = 0} = row;
                return h("span", numberFormat(amount / 100));
            }
        },
        {
            prop: "billStatusName",
            label: "账单状态",
            width: 90,
            render: (h, {row}) => {
                const {billStatusName} = row;
                return billStatusName === '已支付' ? '已缴费' : billStatusName
            }
        },
        {
            prop: "createDated",
            label: "操作时间",
            width: 120,
            render: (h, {row}) => {
                const {createDated} = row;
              return h("span", timeFormat(new Date(createDated)) );
            }

        },
        // {
        //     prop: "comment",
        //     label: "备注",
        // },
        {
            prop: "createUser",
            label: "操作人",
            width: 100,
        },
        // {
        //     prop: "weChatPaySerialNumber",
        //     label: "微信支付流水号",
        //     width: 225
        // },
    ];
}

